/**
 * 带标题的密码输入框组件
  import { LockOutlined } from '@ant-design/icons';
  <XInputPassword
    label='密码'
    placeholder="请输入密码"
    prefix={<LockOutlined />}
    value={this.state.number}
    bindThis={this}
    bindName='number'
  />
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class InputModule extends React.Component {

    _onChange = (e) => {
        const { bindThis, bindName, onChange } = this.props;
        if (onChange) {
            onChange(e.target.value);
        }
        if (bindThis && bindName) {
            bindThis.setState({ [bindName]: e.target.value });
        }
    }

    render() {
        const { label, style } = this.props;
        const { bindThis, bindName, inputStyle, isRequired, ...otherProps } = this.props;

        return (
            <div style={style} className={styles.container}>
                <span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span>
                <div className={styles.label}>{label}</div>
                <div className={styles.inputbox}>
                    <Input.Password
                        {...otherProps}
                        style={inputStyle}
                        className={styles.input}
                        onChange={this._onChange}
                    />
                </div>
            </div>
        );
    }
}

InputModule.defaultProps = {
    isRequired: false,
    visibilityToggle: true,
};

InputModule.propTypes = {
    bindThis: PropTypes.object, // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    style: PropTypes.object, // 组件样式
    inputStyle: PropTypes.object, // 输入框样式
    label: PropTypes.string, // 标题名称
    placeholder: PropTypes.string, // 提示信息
    onChange: PropTypes.func, // 输入框内容变化时的回调函数
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 输入框内容，格式为字符串或数字格式
    isRequired: PropTypes.bool, // 当为true时样式添加红星
    visibilityToggle: PropTypes.bool, // 是否显示切换按钮
};

export default InputModule;