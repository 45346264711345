import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, cellWidth } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = [
        {
            title: 'APP类型',
            dataIndex: 'appTypeName',
            key: 'appTypeName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: 'APP名称',
            dataIndex: 'appName',
            key: 'appName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '系统类型',
            dataIndex: 'systemTypeName',
            key: 'systemTypeName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '版本号',
            dataIndex: 'newVersion',
            key: 'newVersion',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '最小支持版本号',
            dataIndex: 'minVersion',
            key: 'minVersion',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: 'APP下载地址',
            dataIndex: 'apkUrl',
            key: 'apkUrl',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新备注',
            dataIndex: 'updateDescription',
            key: 'updateDescription',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '更新类型',
            dataIndex: 'updateModeName',
            key: 'updateModeName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '热更新key',
            dataIndex: 'hotUpdateKey',
            key: 'hotUpdateKey',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '修改人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '修改时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            fixed: 'right',
            render: (key, record) => this._renderActionCell(record)
        }
    ];

    _takeDataSource = (dataList) => {
        if (Array.isArray(dataList) && dataList.length > 0) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (record) => {
        const { tableAction } = this.props;
        const { state } = record;

        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('edit', record)}>编辑</div>
                {
                    state == 0 ? <div className={styles.item} onClick={() => tableAction('on', record)}>启用</div> : <div className={styles.item} onClick={() => tableAction('off', record)}>禁用</div>
                }
                <div className={styles.item} onClick={() => tableAction('delete', record)}>删除</div>
            </div>
        );
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList = [{ id: 1, a: 1 }, { id: 2, a: 2 }] } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%' }}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
};

export default TableContent;
