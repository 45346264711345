import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination, XTableSelectionCount } from '@/components/xqxc_ui';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, tooltipClick, cellWidth } from '@/components/TableCell';

class TableContent extends React.Component {

    state = {
        selectedRowKeys: []
    }
    _takeColumns = []

    componentDidMount() {
        this._renderTableColumns();
    }

    _renderTableColumns() {
        let {tableType} = this.props;
        this._takeColumns = [
            {
            title: '所属商户',
            dataIndex: 'tel',
            key: 'tel',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
            {
            title: '客户昵称',
            dataIndex: 'nickname',
            key: 'nickname',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
            {
            title: '消息总量',
            dataIndex: 'accountSponsorName',
            key: 'accountSponsorName',
            width: cellWidth.normal,
            align: 'center',
            render: tooltip
            },
            {
            title: '会话总量',
            dataIndex: 'state',
            key: 'state',
            width: cellWidth.normal,
            align: 'center',
            },

            {
            title: '有效会话数量',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
            {
            title: '结束会话数量',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
            {
            title: '会话总时长',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
            {
            title: '单会话平均消息数',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
            {
            title: '单会话平均时长',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            align: 'center',
            onCell: tooltipStyle,
            render: tooltip
            },
        
        
          
    ];
        
    }
    

    

    _takeDataSource = (dataList) => {
        if (dataList) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderTextCell = (text) => {
        return (
            <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {text}
            </div>
        );
    };
    _renderActionCell = (key, record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction(key, 'info', record)}>详情</div>
                <div className={styles.item} onClick={() => tableAction(key, 'recommend', record)}>推荐明细</div>
            </div>
        );
    };

    // 表格复选输入
    _rowSelection = () => {
        const { selectedRowKeys } = this.state;
        return {
            selectedRowKeys,
            onChange: (selectedRowKeys) => {
                this.setState({ selectedRowKeys });
                this.props.tableAction(null, 'batch', [...selectedRowKeys]);
            }
        };
    };

    // 清空表格复选框
    _cleanAllSelected = () => {
        this.setState({ selectedRowKeys: [] });
        this.props.tableAction(null, 'batch', []);
    }

    render() {
        const { selectedRowKeys } = this.state;
        const { paginationChange, tab } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer} style={{ height: '100%'}}>
                {/* <XTableSelectionCount selectedNum={selectedRowKeys.length} onClean={this._cleanAllSelected} /> */}
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    // rowSelection={this._rowSelection()}
                    rowKey={record => record.id}
                    columns={this._takeColumns}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
    tab: PropTypes.string,
    tableType: PropTypes.string,
};

export default TableContent;
