import { createModel } from '@/store/tools';

const model = {
    namespace: 'account',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'listKfAdmin': T('listKfAdmin'),
            'getKfAdmin': T('getKfAdmin'),
        },
        actions: {
            'listKfAdmin': A('listKfAdmin'), // 列表
            'getKfAdmin': A('getKfAdmin'), // 详情
        },
        sagas: {
            'listKfAdmin': S('listKfAdmin', '/?cmd=com.xqxc.api.kf.KFAdminService.listKfAdmin'),
            'getKfAdmin': S('getKfAdmin', '/?cmd=com.xqxc.api.kf.KFAdminService.getKfAdmin'),
        },
        reducers: {
            'listKfAdminList': R('listKfAdmin', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'KfAdminInfo': R('getKfAdmin', {}),
        }
    })
};
export default createModel(model);